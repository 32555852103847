<template>
  <v-card class="desktop_card">
    <v-card-title>
      <v-row no-gutters>
        <v-col class="text-left" cols="7">
          <label class="--v-primary-base text-left text-capitalize">{{ $t(`label.bankDetail`) }}</label>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndDown" class="text-center" cols="5">
          <!-- <v-btn v-if="$vuetify.breakpoint.mdAndDown" :ripple="false" outlined rounded class="text-uppercase font-weight-bold text-center navigator_active--text" @click="openNewBankAccountDialog">{{ $t(`button.add`) }}
          </v-btn> -->
          <v-btn class="text-capitalize px-10" color="primary" depressed @click="openNewBankAccountDialog">
            {{ $t(`button.add`) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card v-if="!bankAccountDialogShow && $vuetify.breakpoint.lgAndUp" height="80%" class="profile-detail-card" elevation="0">
      <v-row no-gutters align="end" justify="start" class="px-8 pa-4">
        <v-col cols="2">
          <label class="field-name">{{ $t(`field.bank`) }}</label>
        </v-col>
        <v-col cols="2">
          <label class="field-name">{{ $t(`field.bankHolderName`) }}</label>
        </v-col>
        <v-col cols="3">
          <label class="field-name">{{ $t(`field.depositingBankAccountNumber`) }}</label>
        </v-col>
        <v-col cols="2">
          <label class="field-name" v-if="currency == 'INR'">{{ $t(`field.ifscCode`) }}</label>
        </v-col>
        <v-col cols="2">
          <label class="field-name" v-if="currency == 'INR'">{{ $t(`field.upi`) }}</label>
        </v-col>
        <v-col class="text-right" cols="1">
          <!-- <v-btn :ripple="false" outlined rounded class="text-uppercase font-weight-bold text-center navigator_active--text" @click="openNewBankAccountDialog">{{ $t(`button.add`) }}
          </v-btn> -->
          <v-btn class="text-capitalize px-5" color="primary" depressed @click="openNewBankAccountDialog">
            {{ $t(`button.add`) }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="px-8">
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters align="end" class="px-8 pt-4 align-center" v-for="(bankAccount, index) in memberBankAccounts" :key="bankAccount.code">
        <v-col cols="2" class="pr-1">
          <label>{{ bankAccount.bankName }}</label>
        </v-col>
        <v-col cols="2">
          <label>{{ bankAccount.accountName }}</label>
        </v-col>
        <v-col cols="3">
          <label>{{ bankAccount.accountNumber }}</label>
        </v-col>
        <v-col cols="2" v-if="currency == 'INR'">
          <label>{{ bankAccount.ifscCode == null ? '-' : bankAccount.ifscCode }}</label>
        </v-col>
        <v-col cols="2" v-if="currency == 'INR'">
          <label>{{ bankAccount.upiAddress == null ? '-' : bankAccount.upiAddress }}</label>
        </v-col>
        <v-col cols="12" class="pt-4">
          <v-divider v-if="index !== memberBankAccounts.length - 1"></v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters align="end" class="px-8 pt-4" v-if="memberBankAccounts.length == 0">
        <v-col cols="12" class="pt-4 text-center">
          <label class="field-name">{{ $t(`label.noBankAccount`) }}</label>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else-if="!bankAccountDialogShow && $vuetify.breakpoint.mdAndDown" height="80%" class="profile-detail-card" elevation="0">
      <v-row no-gutters align="center" justify="start" class="px-8 pa-4">
        <v-col cols="12" v-for="bankAccount in memberBankAccounts" :key="bankAccount.code">
          <v-row no-gutters class="py-1">
            <v-col cols="6">
              <label class="field-name">{{ $t(`field.bank`) }}</label>
            </v-col>
            <v-col cols="6">
              <label>{{ bankAccount.bankName }}</label>
            </v-col>
          </v-row>
          <v-row no-gutters class="py-1">
            <v-col cols="6">
              <label class="field-name">{{ $t(`field.bankHolderName`) }}</label>
            </v-col>
            <v-col cols="6">
              <label>{{ bankAccount.accountName }}</label>
            </v-col>
          </v-row>
          <v-row no-gutters class="py-1">
            <v-col cols="6">
              <label class="field-name">{{ $t(`field.depositingBankAccountNumber`) }}</label>
            </v-col>
            <v-col cols="6">
              <label>{{ bankAccount.accountNumber }}</label>
            </v-col>
          </v-row>
          <v-row no-gutters class="py-3">
            <v-col cols="12">
              <v-divider class=""></v-divider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="bankAccountDialogShow" height="80%" class="profile-detail-card" elevation="0">
      <v-form ref="bankAccountForm">
        <v-row no-gutters align="end" class="px-8 pt-4">
          <v-col lg="4" md="5">
            <app-form-field
              :class="'mt-2'"
              v-model.trim="selectedBankCodeValue"
              :enabledLabel="true"
              :items="banks"
              :label="$t(`field.bank`)"
              :placeholder="$t(`fieldHint.fillUpHere`)"
              type="select"
              :rules="this.selectedBankCodeValue == 0 ? [] : validator.dropDownListRules()"
              :displayText="this.getBankDisplayText"
            ></app-form-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="end" class="px-8">
          <v-col lg="4" md="5">
            <app-form-field
              v-if="this.selectedBankCodeValue == '0'"
              v-model.trim="otherBankName"
              :enabledLabel="true"
              :label="$t(`field.bankName`)"
              :placeholder="$t(`fieldHint.fillUpHere`)"
              :rules="validator.requiredRules(1, 50)"
            ></app-form-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="end" class="px-8">
          <v-col lg="4" md="5">
            <app-form-field
              :readonly="memberInfo.name != '' && memberInfo.name != null"
              v-model.trim="bankAccountObj.memberBankAccountName"
              :enabledLabel="true"
              :label="$t(`field.accountName`)"
              :placeholder="$t(`fieldHint.fillUpHere`)"
              :rules="validator.requiredRules(1, 50)"
            ></app-form-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="end" class="px-8">
          <v-col lg="4" md="5">
            <app-form-field
              v-model.trim="bankAccountObj.memberBankAccountNumber"
              :enabledLabel="true"
              :label="$t(`field.accountNumber`)"
              :placeholder="$t(`fieldHint.fillUpHere`)"
              :rules="validator.bankAccountNumberRules(1, 20)"
            ></app-form-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="end" class="px-8" v-if="currency == 'INR'">
          <v-col lg="4" md="5">
            <app-form-field
              v-model.trim="bankAccountObj.ifscCode"
              :enabledLabel="true"
              :label="$t(`field.ifscCode`)"
              :placeholder="$t(`fieldHint.fillUpHere`)"
              :rules="validator.requiredRules(1, 20)"
            ></app-form-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="end" class="px-8" v-if="currency == 'INR'">
          <v-col lg="4" md="5">
            <app-form-field
              v-model.trim="bankAccountObj.upiAddress"
              :enabledLabel="true"
              :label="$t(`field.upi`)"
              :placeholder="$t(`fieldHint.fillUpHere`)"
              :rules="validator.upiRules()"
            ></app-form-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="end" class="px-8">
          <v-col lg="4" md="5">
            <app-button class="dialog-button" :action="this.addBankAccount" :title="$t(`button.submit`)"></app-button>
          </v-col>
        </v-row>
        <v-row no-gutters align="end" class="px-8 pb-4">
          <v-col lg="4" md="5" style="margin-top: 10px">
            <app-button class="dialog-button" :action="this.closeBankAccountDialog" :title="$t(`button.cancel`)"></app-button>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <app-dialog ref="personalOTPDialog" :dialog-show="otpDialogShow" :close-action="closeOTPDialog" :max-width="400" :title="$t(`label.verifyPhoneNo`)">
      <v-form ref="otpForm">
        <template>
          <captcha ref="captchaCode" v-model.trim="captcha" :isOptional="true" :errorMsg="captchaErrorMsg" :rules="validator.captchaRules()"></captcha>
          <app-form-field :enabled-label="true" :value="memberPhonePrefix + ' ' + memberInfo.mobile.value" :label="$t(`field.phoneNumber`)" :readonly="true" class="otpPhoneNo pb-2" />
          <v-row no-gutters class="pb-5">
            <v-col cols="5">
              <div class="mr-2">
                <v-btn
                  height="auto"
                  :disabled="$store.state.member.smsVerificationSent.countDownTimer > 0"
                  color="black"
                  depressed
                  class="otp-button theme-button font-weight-bold yellow--text mr-2"
                  @click="requestSmsVerificationCode"
                >
                  {{ $store.state.member.smsVerificationSent.countDownTimer > 0 ? formatSeconds($store.state.member.smsVerificationSent.countDownTimer) : $t(`button.requestOtp`) }}
                </v-btn>
              </div>
            </v-col>
            <v-col>
              <app-form-field v-model.trim="verificationCode" :enabled-label="false" :placeholder="$t(`fieldHint.fillUpHere`)" :rules="validator.requiredRules()" type="text" />
            </v-col>
          </v-row>
          <v-row no-gutters class="d-flex justify-center align-center">
            <app-button class="otpSubmitButton full-width" :action="verifySmsCode" :title="$t(`button.submit`)" />
          </v-row>
        </template>
      </v-form>
    </app-dialog>
  </v-card>
</template>

<script>
import AppAccount from '@/components/bank/account.vue'
import { SESSION, PhonePrefixes } from '@/constants/constants'
import { uiHelper, locale, errorCodeHelper, formValidator } from '@/util'
import stingFormat from 'string-format'
import { MEMBER_DETAIL, MEMBER_PHONE_VERIFICATION_STATUS, MEMBER_REST_OTPRESULT, MEMBER_SMS_REQUEST, MEMBER_SMS_VERIFY } from '@/store/member.module'
import {
  PAYMENT_DELETE_MEMBER_BANK_ACCOUNT,
  PAYMENT_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE,
  PAYMENT_UPDATE_MEMBER_BANK_ACCOUNT,
  PAYMENT_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE,
  PAYMENT_NEW_MEMBER_BANK_ACCOUNT,
  PAYMENT_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE
} from '@/store/payment.module'
import { ROUTE_NAME } from '@/constants/route.constants'
import Captcha from '@/components/Captcha.vue'

export default {
  name: 'bankAccount',
  metaInfo: {
    title: `${locale.getMessage('meta.bankInfo_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [
      {
        charset: 'utf-8'
      },
      {
        name: 'description',
        content: `${locale.getMessage('meta.bankInfo_description')}`
      },
      {
        name: 'keyword',
        content: `${locale.getMessage('meta.bankInfo_keyword')}`
      }
    ]
  },
  components: {
    Captcha,
    AppAccount
  },
  data: () => ({
    otpDialogShow: false,
    memberPhonePrefix: '',
    verificationCode: '',
    noAccount: true,
    isUpdateBank: true,
    bankAccountDialogShow: false,
    playerBankAccountName: '',
    transactionObj: {
      /* MEMBER INFO */
      memberBankAccountId: '',
      memberBankAccountName: '',
      memberBankAccountNumber: '',
      memberBankName: ''
    },
    bankAccountObj: {
      memberBankAccountId: '',
      memberBankAccountName: '',
      memberBankAccountNumber: '',
      memberBankName: '',
      memberBankProvince: '',
      memberBankBranch: '',
      memberBankCity: '',
      ifscCode: '',
      upiAddress: '',
      saveNewBank: false,
      memberCode: localStorage.getItem(SESSION.USERNAME),
      isUpdateBank: false
    },
    personalInfo: {},
    validator: formValidator,
    stingFormat: stingFormat,
    selectedBankCodeValue: '',
    otherBankName: '',
    currency: uiHelper.getCurrency(),
    captcha: '',
    captchaErrorMsg: undefined
  }),
  computed: {
    memberInfo() {
      return this.$store.state.member.info
    },
    banks() {
      return this.$store.state.payment.banks
    },
    memberBankAccounts() {
      return this.$store.state.payment.memberBankAccounts.filter(x => x.id != '0')
    },
    deleteMemberBankAccountResponseComplete() {
      return this.$store.state.payment.deleteMemberBankAccountResponse.complete
    },
    updateMemberBankAccountResponseComplete() {
      return this.$store.state.payment.updateMemberBankAccountResponse.complete
    },
    newMemberBankAccountResponseComplete() {
      return this.$store.state.payment.newMemberBankAccountResponse.complete
    },
    memberMobileStatus() {
      return this.$store.state.member.info.mobile.is_verified
    },
    smsVerificationResult() {
      return this.$store.state.member.smsVerificationResult.loaded
    }
  },
  watch: {
    smsOTPErrorMsg() {
      let errorCode = this.$store.state.member.smsVerificationSent.code
      if (errorCode == -1) {
        this.$parent.openAlert(false, locale.getMessage('fieldErrorMessage.invalidCaptchaCode'))
      } else {
        this.errorDialog(errorCode)
      }
    },
    smsVerificationResult() {
      let response = this.$store.state.member.smsVerificationResult
      //show alert on repsonse //loaded =true : back from server with response
      if (response.loaded) {
        if (response.success) {
          //if reponse=phone verified show alert and close while otp window
          let successMsg = locale.getMessage('message.otpSuccessVerification')
          this.$parent.openAlert(true, successMsg)
          this.otpDialogShow = false

          this.$store.dispatch(`${MEMBER_PHONE_VERIFICATION_STATUS}`)
        } else {
          if (response.code == -1) {
            this.$parent.openAlert(response.success, locale.getMessage('fieldErrorMessage.invalidCaptchaCode'))
          } else {
            let failMsg = locale.getMessage('message.otpFailVerification')
            this.$parent.openAlert(false, failMsg)
          }
        }
        this.$store.dispatch(`${MEMBER_REST_OTPRESULT}`)
      }
    },
    memberBankAccounts() {
      //this.preSelectMemberBankAccount()
    },
    deleteMemberBankAccountResponseComplete() {
      let response = this.$store.state.payment.deleteMemberBankAccountResponse
      if (response.complete) {
        this.deleteMemberBankAccountResponseCompleteDialog(response)
        this.$parent.getMemberBankAccount()
        this.$store.dispatch(`${PAYMENT_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE}`)
      }
    },
    updateMemberBankAccountResponseComplete() {
      let response = this.$store.state.payment.updateMemberBankAccountResponse
      if (response.complete) {
        this.updateMemberBankAccountResponseCompleteDialog(response)
        this.$parent.getMemberBankAccount()
        this.$store.dispatch(`${PAYMENT_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE}`)
      }
    },
    newMemberBankAccountResponseComplete() {
      let response = this.$store.state.payment.newMemberBankAccountResponse
      if (response.complete) {
        this.newMemberBankAccountResponseCompleteDialog(response)
        this.$parent.getMemberBankAccount()
        this.$store.dispatch(`${PAYMENT_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE}`)
        if (response.success) {
          // this.$router.push({
          //     name: ROUTE_NAME.BANK_ACCOUNT,
          // })
          window.location.reload()
        }
      }
    }
  },
  created() {
    this.metaTag()
    this.$parent.getBank()
    this.$parent.getMemberBankAccount()
    this.getMemberDetail()
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      const Linksenin = `https://${domain}/en-IN${cleanPath}`
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    },
    formatSeconds(secs) {
      const pad = n => (n < 10 ? `0${n}` : n)

      const h = Math.floor(secs / 3600)
      const m = Math.floor(secs / 60) - h * 60
      const s = Math.floor(secs - h * 3600 - m * 60)

      return `${pad(m)}:${pad(s)}`
    },
    promptOTPVerification() {
      if (uiHelper.getCurrency() !== 'NPR' && uiHelper.getCurrency() !== 'INR') {
        let memberInfo = this.$store.state.member.info
        if (memberInfo.mobile != null && memberInfo.mobile != undefined) {
          this.memberPhonePrefix = PhonePrefixes.filter(a => {
            return a.value.toLowerCase() == memberInfo.currency.toLowerCase()
          })
          this.memberPhonePrefix = this.memberPhonePrefix.length > 0 ? this.memberPhonePrefix[0].text : '+60'
          if (memberInfo.mobile.is_verified == 0) {
            /*block add bank , prompt otp phone verification*/
            this.otpDialogShow = true
          }
        }
      }
    },
    async verifySmsCode() {
      if (this.$refs.otpForm.validate()) {
        let registerObj = {
          verificationCode: this.verificationCode,
          mobileNumber: this.memberInfo.mobile.value,
          memberCode: this.memberInfo.memberCode,
          captchaId: this.$refs.captchaCode.$refs.code.id,
          captchaCode: this.captcha
        }
        this.$store.dispatch(`${MEMBER_SMS_VERIFY}`, {
          registerObj
        })
      }
    },
    requestSmsVerificationCode() {
      this.$store.state.member.smsVerificationSent.success = false
      //if (this.$refs.registerForm2.validate()) {

      if (this.captcha != '') {
        let registerObj = {}
        registerObj.domain = uiHelper.getHostname()
        registerObj.language = 'en'
        registerObj.currency = this.memberInfo.currency
        registerObj.memberCode = this.memberInfo.memberCode
        registerObj.mobileNumber = this.memberInfo.mobile.value
        registerObj.captchaId = this.$refs.captchaCode.$refs.code.id
        registerObj.captchaCode = this.captcha

        this.$store.dispatch(`${MEMBER_SMS_REQUEST}`, {
          registerObj
        })
      }
      //}
    },
    closeOTPDialog() {
      this.otpDialogShow = false
      this.$router.push({
        name: ROUTE_NAME.HOME
      })
    },
    getMemberDetail() {
      this.$store.dispatch(MEMBER_DETAIL).then(() => {
        this.promptOTPVerification()
      })
    },
    preSelectMemberBankAccount() {
      if (this.memberBankAccounts.length > 0) {
        let memberBankAccounts = this.memberBankAccounts.find(x => x.id != '0')
        if (memberBankAccounts != null && memberBankAccounts != undefined) {
          this.transactionObj.memberBankAccountId = memberBankAccounts.id
          this.transactionObj.memberBankName = memberBankAccounts.bankName
          this.transactionObj.memberBankAccountNumber = memberBankAccounts.accountNumber
          this.transactionObj.memberBankAccountName = memberBankAccounts.accountName
          this.noAccount = false
        } else {
          this.noAccount = true
        }
      } else {
        this.noAccount = true
      }
    },
    confirmationDeleteMemberBankAccount(data) {
      let dialog = this.$parent.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`message.deleteBankAccount`)
      dialog.message.push(locale.getMessage(`message.confirmDeleteBankAccount`))
      dialog.message.push(locale.getMessage('field.bankName') + ' : ' + data.bankName)
      dialog.message.push(locale.getMessage('field.accountName') + ' : ' + data.accountName)
      dialog.message.push(locale.getMessage('field.accountNumber') + ' : ' + data.accountNumber)
      dialog.button.push({
        title: locale.getMessage(`label.confirm`),
        action: () => this.deleteMemberBankAccount(data)
      })
      dialog.button.push({
        title: locale.getMessage(`label.cancel`),
        action: this.$parent.$parent.closePageDialog
      })
      this.$parent.$parent.openPageDialog(dialog)
    },
    deleteMemberBankAccount(data) {
      this.$parent.$parent.closePageDialog
      let obj = {
        memberBankAccountId: data.id
      }
      this.$store.dispatch(`${PAYMENT_DELETE_MEMBER_BANK_ACCOUNT}`, {
        obj
      })
    },
    deleteMemberBankAccountResponseCompleteDialog(response) {
      let dialog = this.$parent.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.$parent.$parent.closePageDialog
        })
      } else {
        dialog.message.push(locale.getMessage(`message.memberBankAccountDeleted`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.$parent.$parent.closePageDialog
        })
      }
      this.$parent.$parent.openPageDialog(dialog)
    },
    updateBankAccountData(obj) {
      this.$store.dispatch(`${PAYMENT_UPDATE_MEMBER_BANK_ACCOUNT}`, {
        obj
      })
    },
    updateMemberBankAccountResponseCompleteDialog(response) {
      let dialog = this.$parent.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.$parent.$parent.closePageDialog
        })
      } else {
        this.closeBankAccountDialog()
        dialog.message.push(locale.getMessage(`message.memberBankAccountUpdated`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.$parent.$parent.closePageDialog
        })
      }
      this.$parent.$parent.openPageDialog(dialog)
    },
    newBankAccountData(bankAccountObj) {
      this.$store.dispatch(`${PAYMENT_NEW_MEMBER_BANK_ACCOUNT}`, {
        bankAccountObj
      })
    },
    newMemberBankAccountResponseCompleteDialog(response) {
      if (response.code != 0 && !response.success) {
        this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
      } else {
        this.$parent.openAlert(true, locale.getMessage(`message.memberBankAccountCreated`))
      }
    },
    submitBankAccountData(data) {
      if (data.isUpdateBank) {
        this.updateBankAccountData(data)
      } else {
        this.newBankAccountData(data)
      }
    },
    openEditBankAccountDialog(data) {
      this.bankAccountObj = {
        memberBankAccountId: data.id,
        memberBankAccountName: data.accountName,
        memberBankAccountNumber: data.accountNumber,
        memberBankName: data.bankName,
        memberBankProvince: data.bankProvince,
        memberBankBranch: data.bankBranch,
        memberBankCity: data.bankCity,
        saveNewBank: true,
        memberCode: localStorage.getItem(SESSION.USERNAME),
        isUpdateBank: true
      }
      this.bankAccountDialogShow = true
    },
    openNewBankAccountDialog() {
      this.bankAccountObj = null
      this.bankAccountObj = {
        memberBankAccountName: this.memberInfo.name,
        memberBankAccountId: this.transactionObj.memberBankAccountId,
        memberCode: localStorage.getItem(SESSION.USERNAME)
      }
      this.bankAccountDialogShow = true
    },
    closeBankAccountDialog() {
      this.bankAccountDialogShow = false
    },
    addBankAccount() {
      if (this.$refs.bankAccountForm.validate()) {
        if (this.selectedBankCodeValue != 0 && this.selectedBankCodeValue != '0') {
          this.bankAccountObj.memberBankAccountId = this.bankAccountObj.memberBankAccountId != '' ? this.bankAccountObj.memberBankAccountId : '0'
          this.bankAccountObj.memberBankName = this.banks.find(x => x.value == this.selectedBankCodeValue).name
          this.bankAccountObj.bankCode = this.selectedBankCodeValue
        } else {
          //this.bankAccountObj.memberBankAccountId = this.selectedBankCodeValue.toString()
          this.bankAccountObj.memberBankName = this.otherBankName
        }
        /* Force Save Bank if Compenent Open as Edit Bank */
        if (this.isUpdateBank) this.bankAccountObj.saveNewBank = true
        /* Update Value on Deposit or Withdrawal */
        this.submitBankAccountData(this.bankAccountObj)
      }
    },
    getBankDisplayText(data) {
      if (data.value == 0) {
        return locale.getMessage(`bankName.${data.displayText}`)
      } else {
        return data.displayText
      }
    }
  }
}
</script>

<style lang="scss">
.bank-account-card-container {
  max-height: 550px;
  overflow-y: auto;
}

.card_container {
  margin-top: 70px;
  border-radius: 20px !important;

  &.nonBanking {
    margin-top: 0;
  }
}

@media (max-width: 599px) {
  .card_container {
    margin-top: 20px;
    border-radius: 20px !important;
  }
}
</style>
